import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BlogPost from "../components/BlogPost";
import BlogPostModal from "../components/BlogPostModal";

function Blog() {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const triaBgImage = process.env.PUBLIC_URL + "/assets/tria-background.jpg";
  const blogReelRef = useRef(null);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  // Check for dark mode on component mount and when it changes
  useEffect(() => {
    const checkDarkMode = () => {
      const isDark = document.body.classList.contains("dark-mode");
      setIsDarkMode(isDark);
    };

    // Initial check
    checkDarkMode();

    // Set up a MutationObserver to watch for class changes on the body
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          checkDarkMode();
        }
      });
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        // Load the list of blog posts from a static JSON file
        const response = await fetch("/blog-posts.json");
        const postList = await response.json();

        // Fetch the content of each blog post
        const postsWithContent = await Promise.all(
          postList.map(async (post) => {
            const contentResponse = await fetch(`/blog-posts/${post.slug}.md`);
            const content = await contentResponse.text();
            return { ...post, content };
          })
        );

        setPosts(postsWithContent);

        // Log only the titles of the blog posts
        console.log(
          "Blog post titles:",
          postsWithContent.map((post) => post.title)
        );
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        // If you want to handle errors, you can add error handling logic here
      }
    };

    fetchBlogPosts();
  }, []);

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
  };

  const updateArrowVisibility = () => {
    if (blogReelRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = blogReelRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    updateArrowVisibility();
    window.addEventListener("resize", updateArrowVisibility);
    return () => window.removeEventListener("resize", updateArrowVisibility);
  }, [posts]);

  const scrollBlogReel = (direction) => {
    if (blogReelRef.current) {
      const scrollAmount = blogReelRef.current.offsetWidth * 0.8;
      blogReelRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
      setTimeout(updateArrowVisibility, 500); // Update after scroll animation
    }
  };

  return (
    <div>
      <div
        className="hero-content"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "25vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h2 className="text-4xl font-bold mt-4">
          <span className="gradient-text">{t("blog.hero.insights")}</span>{" "}
          <span className="blinking-text-1">{t("blog.hero.and")}</span>{" "}
          <span className="gradient-text">{t("blog.hero.updates")}</span>{" "}
        </h2>
        <div className="mt-4 mb-4">
          <button className="btn-primary mx-2">
            <Link to="/contact">{t("blog.getStarted")}</Link>
          </button>
        </div>
      </div>

      <div
        className="content"
        style={{
          margin: "1rem auto",
          padding: "0.75rem",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg">Explore our latest articles and insights</p>
      </div>

      <div
        style={{
          backgroundImage: `url(${triaBgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          minHeight: "50vh",
          marginTop: "1rem",
          padding: "1.5rem 0",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: isDarkMode
              ? "rgba(0, 0, 0, 0.5)"
              : "rgba(255, 255, 255, 0.8)",
          }}
        ></div>
        <div className="container mx-auto px-4 py-4 relative z-10">
          <h3
            className="text-2xl font-bold text-center mb-4"
            style={{ color: isDarkMode ? "white" : "black" }}
          >
            {t("blog.latestPosts")}
          </h3>
          <div className="blog-reel-container relative">
            {showLeftArrow && (
              <button
                className="scroll-arrow left absolute left-0 top-1/2 transform -translate-y-1/2 z-10"
                onClick={() => scrollBlogReel("left")}
                aria-label="Scroll left"
                style={{ color: isDarkMode ? "white" : "black" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="24"
                  height="24"
                >
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
              </button>
            )}
            <div
              className="blog-reel flex overflow-x-auto space-x-4 pb-4 px-4"
              ref={blogReelRef}
              onScroll={updateArrowVisibility}
              style={{
                scrollSnapType: "x mandatory",
                WebkitOverflowScrolling: "touch",
                maxWidth: "1400px",
                margin: "0 auto",
              }}
            >
              {posts.map((post) => (
                <div
                  key={post.slug}
                  className="flex-shrink-0"
                  style={{
                    width: "300px",
                    scrollSnapAlign: "start",
                  }}
                >
                  <BlogPost
                    post={post}
                    onClick={() => handlePostClick(post)}
                    isDarkMode={isDarkMode}
                  />
                </div>
              ))}
            </div>
            {showRightArrow && (
              <button
                className="scroll-arrow right absolute right-0 top-1/2 transform -translate-y-1/2 z-10"
                onClick={() => scrollBlogReel("right")}
                aria-label="Scroll right"
                style={{ color: isDarkMode ? "white" : "black" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="24"
                  height="24"
                >
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
      {selectedPost && (
        <BlogPostModal post={selectedPost} onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default Blog;
