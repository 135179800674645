import React, { Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FiChevronDown,
  FiChevronUp,
  FiExternalLink,
  FiFilter,
  FiGithub,
} from "react-icons/fi";
import {
  SiAutodesk,
  SiAutodeskrevit,
  SiBim,
  SiBlender,
  SiJavascript,
  SiNextdotjs,
  SiNodedotjs,
  SiPython,
  SiReact,
  SiTailwindcss,
  SiThreedotjs,
  SiTypescript,
  SiUnity,
  SiVite,
} from "react-icons/si";

// Import the separated components
const ProjectFilter3D = lazy(() => import("./components/ProjectFilter3D"));
const TechStack3D = lazy(() => import("./components/TechStack3D"));

// Tech stack icons mapping
export const techIcons = {
  react: SiReact,
  javascript: SiJavascript,
  python: SiPython,
  threejs: SiThreedotjs,
  tailwindcss: SiTailwindcss,
  nodejs: SiNodedotjs,
  typescript: SiTypescript,
  blender: SiBlender,
  unity: SiUnity,
  autodesk: SiAutodesk,
  revit: SiAutodeskrevit,
  ifc: SiBim,
  bimcollab: SiBim,
  nextjs: SiNextdotjs,
  vite: SiVite,
  flask: SiPython,
};

// Updated project data with real content
export const projectsData = [
  {
    id: 1,
    title: "armasuisse BUSA Herisau",
    category: "BIM",
    shortDescription: "BIM Management",
    fullDescription:
      "As a designated BIM Pilot project, this initiative implemented comprehensive coordination processes for armasuisse BUSA Herisau across all project phases. The implementation featured full-scale quality control protocols, standardized information exchange workflows, and meticulous documentation procedures. This systematic approach ensured consistent data integrity across architectural, structural, and MEP disciplines throughout the design and construction process.",
    image: "/assets/project1.jpg",
    technologies: ["revit", "ifc", "bimcollab", "python"],
    links: {
      live: "https://www.ar.admin.ch/de/bim-pilotprojekte",
      github: null,
    },
    color: "#2196F3",
  },
  {
    id: 2,
    title: "Rocket & Pi Timber Highrise Project",
    category: "BIM",
    shortDescription:
      "BIM Support: Interfaces and Challenges with BIM to Timber Production",
    fullDescription:
      "In collaboration with innovative timber construction specialists, this project developed a customized BIM-to-production workflow for pioneering timber highrise projects. The solution bridges the technical gap between architectural & structural models (BIM coordination) and CNC manufacturing systems through standardized data transformation protocols.",
    image: "pi-rocket.jpg",
    technologies: ["revit", "python", "javascript", "ifc"],
    links: {
      live: "https://edition.cnn.com/style/article/tallest-residential-timber-building-intl-scli/index.html",
      live2: "https://pi-zug.ch/",
    },
    color: "#2196F3",
  },
  {
    id: 3,
    title: "IFC Timber Facades for Lignum",
    category: "BIM",
    shortDescription: "Modelling in Blender",
    fullDescription:
      "In partnership with Lignum (Swiss timber industry association), this project developed standardized IFC-compatible timber facade components using Blender's parametric and native IFC modeling capabilities. The work produced over 100 timber facades, enabling accurate representation of timber facades while maintaining full IFC standard compliance.",
    image: "lignum-profile.jpg",
    technologies: ["blender", "python", "ifc"],
    links: {
      live: "https://lignumdata.ch/",
      github: null,
    },
    color: "#2196F3",
  },
  {
    id: 4,
    title: "V-Zug Areal Development",
    category: "LCA",
    shortDescription: "Development & Implementation of Sustainability Strategy",
    fullDescription:
      "Creation and implementation of a pioneering sustainability framework for the V-Zug Tech Cluster — a development spanning 17 distinct projects through 2045. The approach integrates life cycle assessment methodologies at each development phase, from conceptual design through construction and operation. By establishing quantifiable sustainability metrics, the framework enables data-driven optimization and has positioned the Tech Cluster Zug as a benchmark for sustainable development in Switzerland, with projected embodied carbon reductions of approximately 50% compared to conventional approaches.",
    image: "tcz.jpg",
    technologies: ["python", "react", "nextjs", "ifc"],
    links: {
      live: "https://techclusterzug.ch/",
      github: null,
    },
    color: "#4CAF50",
  },
  {
    id: 5,
    title: "WaltGalmarini Projects",
    category: "LCA",
    shortDescription: "LCA & BIM Support for Multiple Projects",
    fullDescription:
      "In partnership with WaltGalmarini engineering, LTplus AG developed specialized LCA methodologies for structural systems across diverse building typologies. The approach integrates structural performance optimization with embodied carbon assessment through state-of-the-art modeling techniques. The methodology enables comparative analysis of material alternatives, structural configurations, and construction methods during early design phases. This work has informed critical design decisions on 10+ major projects, resulting in broad-reaching embodied carbon reductions while maintaining structural performance requirements.",
    image: "wg.jpg",
    technologies: ["python", "react", "revit", "nextjs"],
    links: {
      live: "https://www.waltgalmarini.ch/",
      github: null,
    },
    color: "#4CAF50",
  },
  {
    id: 6,
    title: "IfcLCA",
    category: "Software",
    shortDescription: "Integrated BIM-LCA Analysis Platform",
    fullDescription:
      "This flagship software solution bridges the gap between Building Information Modeling and Life Cycle Assessment through automated quantity takeoff and environmental impact calculation. Personally developed at LTplus AG, the system extracts geometric and material data directly from IFC models while applying Swiss-specific environmental impact factors. IfcLCA features customizable material mapping, analysis visualization and detailed reporting capabilities. The solution enables real-time sustainability feedback during design development, transforming traditional LCA from a post-design verification to an integrated design optimization tool.",
    image: "ifclca.jpg",
    technologies: ["react", "threejs", "nextjs", "typescript"],
    links: {
      live: "https://www.ifclca.com/",
      github: "https://github.com/ifclca/ifclca",
    },
    color: "#FF5722",
  },
  {
    id: 7,
    title: "swissLCAdata",
    category: "Software",
    shortDescription: "Swiss-Specific Environmental Impact Database",
    fullDescription:
      "Developed out of frustration with the lack of a comprehensive environmental impact database for Switzerland, this project aims to fill this gap. The database includes all KBOB building materials and components with region-specific impact factors across various environmental indicators. Features include regular data updates, API access and seamless integration with BIM.",
    image: "lcadata.jpg",
    technologies: ["nodejs", "react", "typescript"],
    links: {
      live: "https://www.lcadata.ch/",
      github: "https://github.com/LTplus-AG/swiss-lca-data",
    },
    color: "#FF5722",
  },
  {
    id: 8,
    title: "openBIM API for Model Processing",
    category: "Software",
    shortDescription: "Interoperable BIM Data Processing Framework",
    fullDescription:
      "This advanced API framework enables automated processing of openBIM models through standardized data extraction, transformation, and analysis protocols. Developed at LT+, the system supports comprehensive model querying, custom property management, and geometric processing across multiple IFC schema versions. The implementation includes Swagger documentation & samples code and serves as the foundation for multiple specialized BIM applications.",
    image: "openbim.jpg",
    technologies: ["python", "flask", "javascript", "typescript"],
    links: {
      live: "https://openbim-service-production.up.railway.app/docs",
      github: "https://github.com/louistrue/openBIM-service",
    },
    color: "#FF5722",
  },
  {
    id: 9,
    title: "Nachhaltigkeitsmonitoring Stadt Zürich",
    category: "Software",
    shortDescription: "Sustainability Monitoring Platform",
    fullDescription:
      "In partnership with Zürich municipal authorities, LT+, Yssential Technologies and ekkodale gmbh developed a comprehensive monitoring system that tracks key sustainability metrics across the city's construction portfolio. The platform aggregates data from building information modelling and enables data-driven decision making while providing transparent sustainability performance reporting to stakeholders and the public.",
    image: "zuerich.jpg",
    technologies: ["vite", "react", "nodejs", "typescript", "python"],
    links: {
      live: null,
      github: "https://github.com/LTplus-AG/LCA-Cost-NHMzh",
    },
    color: "#FF5722",
  },
  {
    id: 10,
    title: "Modelchecker für Righetti+Partner",
    category: "Software",
    shortDescription: "In collaboration with opensource.construction",
    fullDescription:
      "This Open Source model checking tool for Righetti+Partner automates quality assurance checking for BIM models. The system validates models against predefined requirements, ensuring consistency and completeness. Starting Q1 2025, the solution will include IDS (Information Delivery Specification) checks, further enhancing compliance verification capabilities.",
    image: "checker.jpg",
    technologies: ["vite", "react", "nodejs", "python"],
    links: {
      live: "https://modelcheck.opensource.construction/",
      github: "https://github.com/opensource-construction/model-checker",
    },
    color: "#FF5722",
  },
];

// Get color based on category
export function getCategoryColor(category) {
  switch (category) {
    case "BIM":
      return "#2196F3";
    case "LCA":
      return "#4CAF50";
    case "Software":
      return "#FF5722";
    default:
      return "#9C27B0";
  }
}

// Simple fallback for TechStack3D
function TechStackFallback({ technologies }) {
  return (
    <div className="flex flex-wrap gap-2 justify-center items-center h-full bg-gray-900 bg-opacity-50 rounded-lg p-4">
      {technologies.map((tech, index) => {
        const Icon = techIcons[tech];
        return (
          <div key={index} className="flex flex-col items-center p-2">
            {Icon && <Icon size={24} className="text-gray-300" />}
            <span className="text-xs text-gray-400 mt-1">{tech}</span>
          </div>
        );
      })}
    </div>
  );
}

// Project Card component
function ProjectCard({ project, expanded, toggleExpand, isLoaded }) {
  const { t, i18n } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const currentLanguage = i18n.language;

  // Get the translated project data
  const getTranslatedProject = () => {
    if (currentLanguage === "en") {
      return project;
    }

    const translatedProject = t(`projects.projectsList.${project.id - 1}`, {
      returnObjects: true,
    });

    // Return translated data or fallback to English
    return {
      ...project,
      title: translatedProject?.title || project.title,
      shortDescription:
        translatedProject?.shortDescription || project.shortDescription,
      fullDescription:
        translatedProject?.fullDescription || project.fullDescription,
    };
  };

  const translatedProject = getTranslatedProject();

  // Get correct image path - use the existing image path structure
  const imagePath =
    project.id === 1
      ? `${process.env.PUBLIC_URL}/assets/projectpics/busa.jpg`
      : project.image.startsWith("/assets")
      ? `${process.env.PUBLIC_URL}${project.image}`
      : `${process.env.PUBLIC_URL}/assets/projectpics/${
          project.image || `project${(project.id % 9) + 1}.jpg`
        }`;

  // Preload the image when needed
  useEffect(() => {
    if (expanded || isLoaded) {
      const img = new Image();
      img.src = imagePath;
      img.onload = () => setImageLoaded(true);
    }
  }, [expanded, imagePath, isLoaded]);

  return (
    <div
      className={`project-card relative rounded-lg shadow-lg ${
        expanded ? "expanded" : "collapsed"
      }`}
      style={{
        borderLeft: `4px solid ${project.color}`,
        minHeight: expanded ? "auto" : "10rem",
        height: "auto",
        transition: "all 0.3s ease-in-out",
        maxWidth: "100%", // Ensure cards don't overflow on mobile
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fallback background before image loads
      }}
      onClick={() => !expanded && toggleExpand()}
    >
      {/* Background image with 3D perspective effect - only load when needed */}
      {(expanded || imageLoaded) && (
        <div
          className={`absolute inset-0 project-card-image ${
            expanded ? "expanded" : "collapsed"
          }`}
          style={{
            backgroundImage: `url(${imagePath})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 0,
          }}
        />
      )}

      <div
        className="p-3 flex justify-between items-start relative z-10"
        onClick={(e) => {
          e.stopPropagation();
          toggleExpand();
        }}
      >
        <div className="flex-1">
          <h3 className="text-lg font-bold mb-1 break-words">
            {translatedProject.title}
          </h3>
          <p className="text-xs mb-1 text-gray-300">
            {translatedProject.shortDescription}
          </p>
          <span
            className="inline-block px-2 py-0.5 text-xs rounded-full"
            style={{ backgroundColor: `${project.color}44`, color: "#ffffff" }}
          >
            {project.category}
          </span>
        </div>
        <button
          className="text-white p-1 hover:bg-black hover:bg-opacity-20 rounded-full ml-2 flex-shrink-0"
          onClick={(e) => {
            e.stopPropagation();
            toggleExpand();
          }}
        >
          {expanded ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
        </button>
      </div>

      {expanded && (
        <div className="p-4 pt-0 relative z-10">
          <div className="h-px w-full bg-white opacity-20 mb-4"></div>

          {/* Featured project image when expanded - lazy loaded */}
          {imageLoaded ? (
            <div
              className="w-full h-48 rounded-lg overflow-hidden mb-4 project-featured-image"
              style={{
                backgroundImage: `url(${imagePath})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          ) : (
            <div className="w-full h-48 rounded-lg overflow-hidden mb-4 bg-gray-800 animate-pulse flex items-center justify-center">
              <span className="text-gray-500">
                {t("projects.interface.loadingImage")}
              </span>
            </div>
          )}

          <p className="mb-4 text-gray-200">
            {translatedProject.fullDescription}
          </p>

          <div className="mb-4">
            <h4 className="text-sm font-bold mb-2 text-gray-100">
              {t("projects.interface.technologiesUsed")}
            </h4>
            {/* Only render TechStack3D when expanded and after initial load */}
            {isLoaded && (
              <div className="h-40 sm:h-60">
                <Suspense
                  fallback={
                    <TechStackFallback technologies={project.technologies} />
                  }
                >
                  <TechStack3D technologies={project.technologies} />
                </Suspense>
              </div>
            )}
            {!isLoaded && (
              <div className="h-40 sm:h-60">
                <TechStackFallback technologies={project.technologies} />
              </div>
            )}
          </div>

          <div className="flex flex-wrap gap-3">
            {project.links.live && (
              <a
                href={project.links.live}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1 text-sm hover:underline"
                onClick={(e) => e.stopPropagation()}
              >
                <FiExternalLink size={14} />
                <span>{t("projects.viewProject")}</span>
              </a>
            )}
            {project.links.live2 && (
              <a
                href={project.links.live2}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1 text-sm hover:underline"
                onClick={(e) => e.stopPropagation()}
              >
                <FiExternalLink size={14} />
                <span>{t("projects.visitSite")}</span>
              </a>
            )}
            {project.links.github && (
              <a
                href={project.links.github}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1 text-sm hover:underline"
                onClick={(e) => e.stopPropagation()}
              >
                <FiGithub size={14} />
                <span>{t("projects.viewCode")}</span>
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

// Main Projects component
function Projects() {
  const { t } = useTranslation();
  const [expandedIds, setExpandedIds] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    categories: [],
    technologies: [],
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialProjectsLoaded, setInitialProjectsLoaded] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Safe check for dark mode that won't fail if document is not ready
  useEffect(() => {
    const checkDarkMode = () => {
      try {
        if (document && document.body) {
          const isDark = document.body.classList.contains("dark-mode");
          setIsDarkMode(isDark);
        }
      } catch (error) {
        console.warn("Could not check dark mode yet:", error);
      }
    };

    // Initial check
    checkDarkMode();

    // Set up a MutationObserver to watch for class changes on the body
    try {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === "class") {
            checkDarkMode();
          }
        });
      });

      if (document && document.body) {
        observer.observe(document.body, { attributes: true });
        return () => observer.disconnect();
      }
    } catch (error) {
      console.warn("Could not set up MutationObserver:", error);
    }
  }, []);

  // Set isLoaded to true after initial render
  useEffect(() => {
    // Small delay to allow the browser to render the initial UI
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  // Load projects in batches for better performance
  useEffect(() => {
    // Initial load - just show first few projects
    setFilteredProjects(projectsData.slice(0, 6));

    // After a short delay, load the rest
    const timer = setTimeout(() => {
      setFilteredProjects(projectsData);
      setInitialProjectsLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  // Handle filter change
  const handleFilterChange = (filters) => {
    setActiveFilters(filters);
  };

  // When filters change, run filtering logic
  useEffect(() => {
    // Only apply filters after all projects are loaded
    if (!initialProjectsLoaded) return;

    let result = [...projectsData];

    // Apply category filters
    if (activeFilters.categories && activeFilters.categories.length) {
      result = result.filter((project) =>
        activeFilters.categories.includes(project.category)
      );
    }

    // Apply technology filters
    if (activeFilters.technologies && activeFilters.technologies.length) {
      result = result.filter((project) =>
        activeFilters.technologies.some((tech) =>
          project.technologies?.includes(tech)
        )
      );
    }

    setFilteredProjects(result);
  }, [activeFilters, initialProjectsLoaded]);

  const toggleExpand = (id) => {
    setExpandedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id]
    );
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Render project grid with proper spacing and layout following Tailwind best practices
  const renderProjectGrid = () => {
    if (!filteredProjects.length) return null;

    // Use the standard Tailwind grid approach with gap for consistent spacing
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pb-16">
        {filteredProjects.map((project) => (
          <div
            key={project.id}
            className={
              // For lone items in last row, center them with col-span and auto margins
              filteredProjects.length % 3 === 1 &&
              project.id === filteredProjects[filteredProjects.length - 1].id
                ? "lg:col-start-2 lg:col-end-3 md:col-span-2 md:mx-auto md:max-w-md w-full"
                : ""
            }
          >
            <ProjectCard
              project={project}
              expanded={expandedIds.includes(project.id)}
              toggleExpand={() => toggleExpand(project.id)}
              isLoaded={isLoaded}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen overflow-auto">
      <div
        className="hero-section w-full relative min-h-screen"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg.jpg)`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-70"></div>
        <div className="relative z-10 flex flex-col items-center w-full py-8">
          {/* Filter toggle button */}
          <div className="w-full max-w-6xl mx-auto px-4 mb-4 flex justify-between items-center">
            <h2 className="text-2xl font-bold text-white">
              {t("projects.hero.work")}
            </h2>
            <button
              onClick={toggleFilters}
              className="bg-gray-800 hover:bg-gray-700 text-white px-4 py-2 rounded-full flex items-center gap-2 transition-all duration-300"
            >
              <FiFilter size={16} />
              <span className="hidden sm:inline">
                {showFilters
                  ? t("projects.interface.hideFilters")
                  : t("projects.interface.filterProjects")}
              </span>
              <span className="inline sm:hidden">
                {t("projects.interface.filter")}
              </span>
            </button>
          </div>

          {/* 3D Filter component with animation - Lazy loaded */}
          <div
            className={`w-full max-w-6xl mx-auto px-4 transition-all duration-500 ease-in-out overflow-hidden ${
              showFilters ? "max-h-96 opacity-100 mb-4" : "max-h-0 opacity-0"
            }`}
          >
            {showFilters && isLoaded && (
              <div className="bg-gray-900 bg-opacity-80 backdrop-blur-sm rounded-xl p-4 shadow-lg">
                <Suspense
                  fallback={
                    <div className="h-60 sm:h-80 w-full flex items-center justify-center">
                      {t("projects.interface.loadingFilters")}
                    </div>
                  }
                >
                  <ProjectFilter3D onFilterChange={handleFilterChange} />
                </Suspense>
              </div>
            )}
          </div>

          {/* Project cards container with scrolling */}
          <div className="max-w-6xl w-full mx-auto px-2 sm:px-4 overflow-y-visible">
            {renderProjectGrid()}

            {!initialProjectsLoaded && (
              <div className="flex justify-center my-8">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
              </div>
            )}

            {initialProjectsLoaded && filteredProjects.length === 0 && (
              <div className="text-center py-12 bg-black bg-opacity-50 rounded-lg">
                <p className="text-xl">
                  {t("projects.interface.noProjectsFound")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
