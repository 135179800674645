import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const triaImage = process.env.PUBLIC_URL + "/assets/tria.jpg";
  const image1 = process.env.PUBLIC_URL + "/assets/lt.jpg";
  const image2 = process.env.PUBLIC_URL + "/assets/talk.jpg";

  // Check for dark mode on component mount and when it changes
  useEffect(() => {
    const checkDarkMode = () => {
      const isDark = document.body.classList.contains("dark-mode");
      setIsDarkMode(isDark);
    };

    // Initial check
    checkDarkMode();

    // Set up a MutationObserver to watch for class changes on the body
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          checkDarkMode();
        }
      });
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  // Common text styles for consistent appearance across languages
  const textStyles = {
    color: isDarkMode ? "white" : "black",
    lineHeight: "1.6",
    fontSize: "1.1rem",
    fontWeight: "normal",
    textAlign: "left",
  };

  return (
    <div>
      <div
        className="hero-content"
        style={{
          backgroundImage: `url(${bgImage})`,
          minHeight: "30vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h2 className="text-4xl font-bold mt-4">
          <span className="gradient-text">{t("about.hero.vision")}</span>{" "}
          <span className="blinking-text-1">{t("about.hero.for")}</span>{" "}
          <span className="gradient-text">{t("about.hero.sustainable")}</span>{" "}
          <span className="blinking-text-2">{t("about.hero.future")}</span>
        </h2>
        <div className="mt-4 mb-4">
          <button className="btn-primary mx-2">
            <Link to="/contact">{t("about.getStarted")}</Link>
          </button>
        </div>
      </div>
      <div
        className="content"
        style={{
          margin: "1.5rem auto",
          padding: "1rem",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg">{t("about.description")}</p>
      </div>
      <div
        className="full-width-image"
        style={{
          backgroundImage: `url(${triaImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "40vh",
          position: "relative",
          marginTop: "1.5rem",
          padding: "2rem 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: isDarkMode
              ? "rgba(0, 0, 0, 0.6)"
              : "rgba(255, 255, 255, 0.8)",
          }}
        ></div>
        <div
          className="content about-content"
          style={{
            position: "relative",
            zIndex: 1,
            width: "100%",
            maxWidth: "1200px",
            backgroundColor: isDarkMode
              ? "rgba(0, 0, 0, 0.4)"
              : "rgba(255, 255, 255, 0.8)",
            backdropFilter: "blur(3px)",
            padding: "1.5rem",
            boxShadow: isDarkMode
              ? "0 4px 6px rgba(0, 0, 0, 0.3)"
              : "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <div className="flex justify-center items-center flex-wrap">
            <div className="m-2 text-center" style={{ width: "20%" }}>
              <img
                src={image1}
                alt="Louis Trümpler"
                style={{
                  width: "180px",
                  maxWidth: "100%",
                  borderRadius: "8px",
                  objectFit: "cover",
                  objectPosition: "center 20%",
                  margin: "0 auto",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                }}
              />
            </div>
            <div className="m-2" style={{ width: "60%" }}>
              <p style={textStyles}>{t("about.founder")}</p>
              <p className="mt-2" style={textStyles}>
                {t("about.experience")}
              </p>
            </div>
            <div className="m-2" style={{ width: "20%" }}>
              <img
                src={image2}
                alt="LT+ at work"
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  maxWidth: "100%",
                  width: "180px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
