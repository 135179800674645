import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MoonIcon, SunIcon } from "lucide-react";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  // Available languages with their codes and names
  const languages = [
    { code: "en", name: t("languageSwitcher.en") },
    { code: "de", name: t("languageSwitcher.de") },
    { code: "fr", name: t("languageSwitcher.fr") },
    { code: "it", name: t("languageSwitcher.it") },
    { code: "rm", name: t("languageSwitcher.rm") },
  ];

  // Get current language
  const currentLanguage =
    languages.find((lang) => lang.code === i18n.language) || languages[0];

  // Toggle dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Change language
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    setIsOpen(false);
    localStorage.setItem("i18nextLng", code);
  };

  // Toggle dark/light mode
  const toggleTheme = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);

    // Apply dark mode class to body
    if (newMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }

    // Save preference to localStorage
    localStorage.setItem("darkMode", newMode ? "true" : "false");
  };

  // Initialize theme from localStorage on component mount
  useEffect(() => {
    // Check if a preference exists in localStorage
    const storedThemePreference = localStorage.getItem("darkMode");

    // If no preference is set, default to dark mode
    if (storedThemePreference === null) {
      // Set dark mode as default
      setIsDarkMode(true);
      document.body.classList.add("dark-mode");
      localStorage.setItem("darkMode", "true");
    } else {
      // Use stored preference
      const savedDarkMode = storedThemePreference === "true";
      setIsDarkMode(savedDarkMode);

      if (savedDarkMode) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
    }
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".language-switcher")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="flex items-center space-x-4">
      {/* Theme toggle button */}
      <button
        onClick={toggleTheme}
        className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
        aria-label={isDarkMode ? t("theme.light") : t("theme.dark")}
        title={isDarkMode ? t("theme.light") : t("theme.dark")}
      >
        {isDarkMode ? (
          <SunIcon className="w-5 h-5 text-yellow-300 theme-toggle-icon" />
        ) : (
          <MoonIcon className="w-5 h-5 text-indigo-600 theme-toggle-icon" />
        )}
      </button>

      {/* Language dropdown */}
      <div className="relative language-switcher">
        <button
          onClick={toggleDropdown}
          className="flex items-center space-x-1 px-3 py-2 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          aria-expanded={isOpen}
          aria-haspopup="true"
        >
          <span>{currentLanguage.code.toUpperCase()}</span>
          <svg
            className={`w-4 h-4 transition-transform ${
              isOpen ? "rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>

        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-black dark:bg-opacity-95 backdrop-blur-sm rounded-md shadow-lg z-50">
            <div className="py-1">
              <div className="px-4 py-2 text-sm text-gray-500 dark:text-gray-400 border-b border-gray-200 dark:border-gray-700">
                {t("languageSwitcher.language")}
              </div>
              {languages.map((language) => (
                <button
                  key={language.code}
                  onClick={() => changeLanguage(language.code)}
                  className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors ${
                    language.code === currentLanguage.code
                      ? "font-bold text-indigo-600 dark:text-indigo-400"
                      : ""
                  }`}
                >
                  {language.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
