import React from "react";

function BlogPost({ post, onClick, isDarkMode }) {
  return (
    <div
      className="blog-post-card rounded-lg overflow-hidden cursor-pointer transform transition-transform duration-300 hover:scale-105"
      onClick={onClick}
      style={{
        backgroundColor: isDarkMode
          ? "rgba(0, 0, 0, 0.5)"
          : "rgba(255, 255, 255, 0.9)",
        boxShadow: isDarkMode
          ? "0 4px 6px rgba(0, 0, 0, 0.3)"
          : "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <img
        src={post.image || "https://via.placeholder.com/300x100"}
        alt={post.title}
        className="w-full h-24 object-cover"
      />
      <div className="p-3">
        <h3
          className="text-base font-semibold mb-1 truncate"
          style={{ color: isDarkMode ? "white" : "black" }}
        >
          {post.title}
        </h3>
        <p
          className="text-xs opacity-80 mb-1"
          style={{ color: isDarkMode ? "white" : "black" }}
        >
          {post.date}
        </p>
        <p
          className="text-xs opacity-90 line-clamp-2"
          style={{ color: isDarkMode ? "white" : "black" }}
        >
          {post.excerpt}
        </p>
      </div>
    </div>
  );
}

export default BlogPost;
