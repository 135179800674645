import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { t } = useTranslation();

  // Check for dark mode on component mount and when it changes
  useEffect(() => {
    const checkDarkMode = () => {
      const isDark = document.body.classList.contains("dark-mode");
      setIsDarkMode(isDark);
    };

    // Initial check
    checkDarkMode();

    // Set up a MutationObserver to watch for class changes on the body
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          checkDarkMode();
        }
      });
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="flex justify-between items-center p-4 relative w-full shadow-sm">
      <div className="flex items-center">
        <NavLink to="/" end>
          <img
            src={isDarkMode ? "/LT+_white.png" : "/LT+_black.png"}
            alt="LTplus Logo"
            className="h-8 w-auto object-contain logo"
          />
        </NavLink>
      </div>

      {/* Desktop menu */}
      <div className="hidden md:flex items-center justify-between flex-grow ml-6">
        <div className="flex items-center">
          <NavLink
            to="/"
            end
            className={({ isActive }) =>
              `email-link mx-2 ${isActive ? "font-bold" : ""}`
            }
          >
            {t("navigation.home")}
          </NavLink>
          <NavLink
            to="/solutions"
            className={({ isActive }) =>
              `email-link mx-2 ${isActive ? "font-bold" : ""}`
            }
          >
            {t("navigation.solutions")}
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              `email-link mx-2 ${isActive ? "font-bold" : ""}`
            }
          >
            {t("navigation.about")}
          </NavLink>
          <NavLink
            to="/projects"
            className={({ isActive }) =>
              `email-link mx-2 ${isActive ? "font-bold" : ""}`
            }
          >
            {t("navigation.projects")}
          </NavLink>
          <NavLink
            to="/blog"
            className={({ isActive }) =>
              `email-link mx-2 ${isActive ? "font-bold" : ""}`
            }
          >
            {t("navigation.blog")}
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              `email-link mx-2 ${isActive ? "font-bold" : ""}`
            }
          >
            {t("navigation.contact")}
          </NavLink>
          <a
            href="https://playground.lt.plus"
            target="_blank"
            rel="noopener noreferrer"
            className="email-link mx-2 inline-flex items-center"
          >
            {t("navigation.ifcPlayground")}
            <svg
              className="w-3 h-3 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </a>
        </div>

        {/* Language Switcher for desktop */}
        <LanguageSwitcher />
      </div>

      {/* Mobile controls */}
      <div className="md:hidden flex items-center">
        {/* Language Switcher for mobile */}
        <LanguageSwitcher />

        {/* Hamburger button for mobile */}
        <button
          className="ml-4 p-2 text-indigo-600 dark:text-white hover:text-gray-300 focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
              }
            />
          </svg>
        </button>
      </div>

      {/* Mobile menu */}
      <div
        className={`${
          isMenuOpen ? "flex" : "hidden"
        } md:hidden absolute top-full left-0 right-0 flex-col bg-white dark:bg-black dark:bg-opacity-95 backdrop-blur-sm py-4 z-50 shadow-md`}
      >
        <NavLink
          to="/"
          end
          className={({ isActive }) =>
            `email-link block py-2 px-4 ${isActive ? "font-bold" : ""}`
          }
          onClick={() => setIsMenuOpen(false)}
        >
          {t("navigation.home")}
        </NavLink>
        <NavLink
          to="/solutions"
          className={({ isActive }) =>
            `email-link block py-2 px-4 ${isActive ? "font-bold" : ""}`
          }
          onClick={() => setIsMenuOpen(false)}
        >
          {t("navigation.solutions")}
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            `email-link block py-2 px-4 ${isActive ? "font-bold" : ""}`
          }
          onClick={() => setIsMenuOpen(false)}
        >
          {t("navigation.about")}
        </NavLink>
        <NavLink
          to="/projects"
          className={({ isActive }) =>
            `email-link block py-2 px-4 ${isActive ? "font-bold" : ""}`
          }
          onClick={() => setIsMenuOpen(false)}
        >
          {t("navigation.projects")}
        </NavLink>
        <NavLink
          to="/blog"
          className={({ isActive }) =>
            `email-link block py-2 px-4 ${isActive ? "font-bold" : ""}`
          }
          onClick={() => setIsMenuOpen(false)}
        >
          {t("navigation.blog")}
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            `email-link block py-2 px-4 ${isActive ? "font-bold" : ""}`
          }
          onClick={() => setIsMenuOpen(false)}
        >
          {t("navigation.contact")}
        </NavLink>
        <a
          href="https://playground.lt.plus"
          target="_blank"
          rel="noopener noreferrer"
          className="email-link block py-2 px-4 inline-flex items-center"
          onClick={() => setIsMenuOpen(false)}
        >
          {t("navigation.ifcPlayground")}
          <svg
            className="w-3 h-3 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </a>
      </div>
    </nav>
  );
}

export default Navigation;
