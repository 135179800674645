import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Analytics } from "@vercel/analytics/react";
import "./i18n"; // Import i18n configuration
import "./App.css";
import "./styles/lightMode.css"; // Import light mode styles (default)
import "./styles/darkMode.css"; // Import dark mode styles (applied conditionally)
import Home from "./pages/Home";
import About from "./pages/About";
import Solutions from "./pages/Solutions";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Projects from "./pages/Projects";
import Navigation from "./components/Navigation";
import NekoAnimation from "./components/NekoAnimation";

function App() {
  const [barriers, setBarriers] = useState([]);
  const { t } = useTranslation();

  // Initialize dark mode early in the application lifecycle
  useEffect(() => {
    // Check if a preference exists in localStorage
    const storedThemePreference = localStorage.getItem("darkMode");

    // If no preference is set, default to dark mode
    if (storedThemePreference === null) {
      // Set dark mode as default
      document.body.classList.add("dark-mode");
      localStorage.setItem("darkMode", "true");
    } else if (storedThemePreference === "true") {
      // Apply dark mode if that's the stored preference
      document.body.classList.add("dark-mode");
    } else {
      // Ensure light mode is applied if that's the preference
      document.body.classList.remove("dark-mode");
    }
  }, []);

  useEffect(() => {
    const updateBarriers = () => {
      const barrierElements = document.querySelectorAll(
        ".three-viewer-container, .card, .btn-primary, .btn-secondary"
      );
      const newBarriers = Array.from(barrierElements).map((el) => {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left,
          right: rect.right,
          top: rect.top,
          bottom: rect.bottom,
        };
      });
      setBarriers(newBarriers);
    };

    updateBarriers();
    window.addEventListener("resize", updateBarriers);
    // Add a MutationObserver to detect DOM changes
    const observer = new MutationObserver(updateBarriers);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      window.removeEventListener("resize", updateBarriers);
      observer.disconnect();
    };
  }, []);

  return (
    <Router>
      <div className="App flex-container">
        <div className="flex-content">
          <header className="App-header">
            <Navigation />
          </header>

          <main className="App-main">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>
        </div>

        <footer className="App-footer mt-8 py-6">
          <p className="font-medium">{t("footer.tagline")}</p>
          <p className="mt-2">
            {t("footer.getInTouch")}{" "}
            <a href="mailto:info@lt.plus" className="email-link">
              info@lt.plus
            </a>
          </p>
        </footer>

        <NekoAnimation barriers={barriers} />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;
