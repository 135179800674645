import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t, i18n } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const triaImage = process.env.PUBLIC_URL + "/assets/tria.jpg";

  // Split the getInTouch translation into two parts for animation
  const getContactText = () => {
    const fullText = t("contact.getInTouch");
    const currentLang = i18n.language;

    // Different splitting logic based on language
    if (currentLang === "en") {
      return { part1: "Get in", part2: "touch" };
    } else if (currentLang === "de") {
      return { part1: "Kontakt", part2: "aufnehmen" };
    } else if (currentLang === "fr") {
      return { part1: "Contactez-", part2: "nous" };
    } else if (currentLang === "it") {
      return { part1: "Mettiti in", part2: "contatto" };
    } else if (currentLang === "rm") {
      return { part1: "Contactar", part2: "nus" };
    } else {
      // Default fallback - split at the middle
      const middle = Math.floor(fullText.length / 2);
      return {
        part1: fullText.substring(0, middle),
        part2: fullText.substring(middle),
      };
    }
  };

  // Check for dark mode on component mount and when it changes
  useEffect(() => {
    const checkDarkMode = () => {
      const isDark = document.body.classList.contains("dark-mode");
      setIsDarkMode(isDark);
    };

    // Initial check
    checkDarkMode();

    // Set up a MutationObserver to watch for class changes on the body
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          checkDarkMode();
        }
      });
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const contactText = getContactText();

  return (
    <div>
      <div
        className="hero-content"
        style={{
          backgroundImage: `url(${bgImage})`,
          minHeight: "25vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h2 className="text-4xl font-bold mt-4">
          <span className="blinking-text-1">{t("contact.hero.lets")}</span>{" "}
          <span className="blinking-text-2">{t("contact.hero.build")}</span>{" "}
          <span className="gradient-text">{t("contact.hero.together")}</span>
        </h2>
        <div className="mt-4 mb-4">
          <button className="btn-primary mx-2">
            <a href="mailto:info@lt.plus">{t("contact.sendEmail")}</a>
          </button>
        </div>
      </div>
      <div
        className="content"
        style={{
          margin: "1rem auto",
          padding: "0.75rem",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg">{t("contact.description")}</p>
      </div>
      <div
        className="full-width-image"
        style={{
          backgroundImage: `url(${triaImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "50vh",
          position: "relative",
          marginTop: "1rem",
          padding: "1.5rem 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: isDarkMode
              ? "rgba(0, 0, 0, 0.5)"
              : "rgba(255, 255, 255, 0.8)",
          }}
        ></div>
        <div
          className="content"
          style={{
            position: "relative",
            zIndex: 1,
            color: isDarkMode ? "white" : "black",
            width: "60%",
            maxWidth: "800px",
            backgroundColor: isDarkMode
              ? "rgba(0, 0, 0, 0.3)"
              : "rgba(255, 255, 255, 0.8)",
            backdropFilter: "blur(2px)",
            padding: "1.5rem",
            boxShadow: isDarkMode
              ? "0 4px 6px rgba(0, 0, 0, 0.3)"
              : "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <h2 className="text-3xl font-semibold">
            <span className="blinking-text-1">{contactText.part1}</span>{" "}
            <span className="gradient-text">{contactText.part2}</span>
          </h2>
          <p className="mt-4">
            {t("contact.office")}
            <br />
            {t("contact.email")}{" "}
            <a href="mailto:info@lt.plus" className="email-link">
              info@lt.plus
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
