import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCategoryColor } from "./Projects";

function Solutions() {
  const { t } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const bgImage = process.env.PUBLIC_URL + "/assets/bg.jpg";
  const triaImage = process.env.PUBLIC_URL + "/assets/tria.jpg";

  // Check for dark mode on component mount and when it changes
  useEffect(() => {
    const checkDarkMode = () => {
      const isDark = document.body.classList.contains("dark-mode");
      setIsDarkMode(isDark);
    };

    // Initial check
    checkDarkMode();

    // Set up a MutationObserver to watch for class changes on the body
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          checkDarkMode();
        }
      });
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  // Colors from Projects.js getCategoryColor function
  const colors = {
    environmental: "#4CAF50", // LCA color
    digital: "#2196F3", // BIM color
    timber: "#9C27B0", // Default color
    software: "#FF5722", // Software color
  };

  return (
    <div>
      <div
        className="hero-content"
        style={{
          backgroundImage: `url(${bgImage})`,
          minHeight: "30vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h2 className="text-4xl font-bold mt-4">
          <span className="gradient-text">
            {t("solutions.hero.innovation")}
          </span>{" "}
          <span className="blinking-text-1">{t("solutions.hero.for")}</span>{" "}
          <span className="gradient-text">
            {t("solutions.hero.sustainable")}
          </span>{" "}
          <span className="blinking-text-2">
            {t("solutions.hero.construction")}
          </span>
        </h2>
        <div className="mt-4 mb-4">
          <button className="btn-primary mx-2">
            <Link to="/contact">{t("solutions.getStarted")}</Link>
          </button>
        </div>
      </div>
      <div
        className="content"
        style={{
          margin: "1.5rem auto",
          padding: "1rem",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg">{t("solutions.description")}</p>
      </div>
      <div
        className="full-width-image"
        style={{
          backgroundImage: `url(${triaImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "45vh",
          position: "relative",
          marginTop: "1.5rem",
          padding: "2rem 1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: isDarkMode
              ? "rgba(0, 0, 0, 0.5)"
              : "rgba(255, 255, 255, 0.8)",
          }}
        ></div>
        <div
          className="content solutions-content"
          style={{
            position: "relative",
            zIndex: 1,
            color: isDarkMode ? "white" : "black",
            width: "100%",
            maxWidth: "1200px",
            backgroundColor: isDarkMode
              ? "rgba(0, 0, 0, 0.3)"
              : "rgba(255, 255, 255, 0.8)",
            backdropFilter: "blur(2px)",
            padding: "1.5rem",
            boxShadow: isDarkMode
              ? "0 4px 6px rgba(0, 0, 0, 0.3)"
              : "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              gap: "1rem",
            }}
          >
            <div
              className="card solution-card"
              style={{
                padding: "1rem",
                borderRadius: "0.5rem",
                backgroundColor: isDarkMode
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(5px)",
                borderLeft: `3px solid ${colors.environmental}`,
                transition: "all 0.3s ease",
                transform: "translateY(0)",
                height: "auto",
                minHeight: "160px",
                boxShadow: isDarkMode
                  ? "0 4px 6px rgba(0, 0, 0, 0.2)"
                  : "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
                e.currentTarget.style.boxShadow = isDarkMode
                  ? "0 10px 25px -5px rgba(0, 0, 0, 0.3)"
                  : "0 10px 25px -5px rgba(0, 0, 0, 0.15)";
                e.currentTarget.style.backgroundColor = isDarkMode
                  ? "rgba(0, 0, 0, 0.6)"
                  : "rgba(255, 255, 255, 1)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow = isDarkMode
                  ? "0 4px 6px rgba(0, 0, 0, 0.2)"
                  : "0 4px 6px rgba(0, 0, 0, 0.1)";
                e.currentTarget.style.backgroundColor = isDarkMode
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.9)";
              }}
            >
              <h3
                className="text-xl font-semibold mb-2"
                style={{ color: colors.environmental }}
              >
                {t("solutions.cards.environmental.title")}
              </h3>
              <p
                className="text-base font-medium"
                style={{
                  color: isDarkMode ? "white" : "black",
                  textShadow: isDarkMode ? "0 1px 2px rgba(0,0,0,0.8)" : "none",
                }}
              >
                {t("solutions.cards.environmental.description")}
              </p>
            </div>
            <div
              className="card solution-card"
              style={{
                padding: "1rem",
                borderRadius: "0.5rem",
                backgroundColor: isDarkMode
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(5px)",
                borderLeft: `3px solid ${colors.digital}`,
                transition: "all 0.3s ease",
                transform: "translateY(0)",
                height: "auto",
                minHeight: "160px",
                boxShadow: isDarkMode
                  ? "0 4px 6px rgba(0, 0, 0, 0.2)"
                  : "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
                e.currentTarget.style.boxShadow = isDarkMode
                  ? "0 10px 25px -5px rgba(0, 0, 0, 0.3)"
                  : "0 10px 25px -5px rgba(0, 0, 0, 0.15)";
                e.currentTarget.style.backgroundColor = isDarkMode
                  ? "rgba(0, 0, 0, 0.6)"
                  : "rgba(255, 255, 255, 1)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow = isDarkMode
                  ? "0 4px 6px rgba(0, 0, 0, 0.2)"
                  : "0 4px 6px rgba(0, 0, 0, 0.1)";
                e.currentTarget.style.backgroundColor = isDarkMode
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.9)";
              }}
            >
              <h3
                className="text-xl font-semibold mb-2"
                style={{ color: colors.digital }}
              >
                {t("solutions.cards.digital.title")}
              </h3>
              <p
                className="text-base font-medium"
                style={{
                  color: isDarkMode ? "white" : "black",
                  textShadow: isDarkMode ? "0 1px 2px rgba(0,0,0,0.8)" : "none",
                }}
              >
                {t("solutions.cards.digital.description")}
              </p>
            </div>
            <div
              className="card solution-card"
              style={{
                padding: "1rem",
                borderRadius: "0.5rem",
                backgroundColor: isDarkMode
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(5px)",
                borderLeft: `3px solid ${colors.timber}`,
                transition: "all 0.3s ease",
                transform: "translateY(0)",
                height: "auto",
                minHeight: "160px",
                boxShadow: isDarkMode
                  ? "0 4px 6px rgba(0, 0, 0, 0.2)"
                  : "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
                e.currentTarget.style.boxShadow = isDarkMode
                  ? "0 10px 25px -5px rgba(0, 0, 0, 0.3)"
                  : "0 10px 25px -5px rgba(0, 0, 0, 0.15)";
                e.currentTarget.style.backgroundColor = isDarkMode
                  ? "rgba(0, 0, 0, 0.6)"
                  : "rgba(255, 255, 255, 1)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow = isDarkMode
                  ? "0 4px 6px rgba(0, 0, 0, 0.2)"
                  : "0 4px 6px rgba(0, 0, 0, 0.1)";
                e.currentTarget.style.backgroundColor = isDarkMode
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.9)";
              }}
            >
              <h3
                className="text-xl font-semibold mb-2"
                style={{ color: colors.timber }}
              >
                {t("solutions.cards.timber.title")}
              </h3>
              <p
                className="text-base font-medium"
                style={{
                  color: isDarkMode ? "white" : "black",
                  textShadow: isDarkMode ? "0 1px 2px rgba(0,0,0,0.8)" : "none",
                }}
              >
                {t("solutions.cards.timber.description")}
              </p>
            </div>
            <div
              className="card solution-card"
              style={{
                padding: "1rem",
                borderRadius: "0.5rem",
                backgroundColor: isDarkMode
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(5px)",
                borderLeft: `3px solid ${colors.software}`,
                transition: "all 0.3s ease",
                transform: "translateY(0)",
                height: "auto",
                minHeight: "160px",
                boxShadow: isDarkMode
                  ? "0 4px 6px rgba(0, 0, 0, 0.2)"
                  : "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
                e.currentTarget.style.boxShadow = isDarkMode
                  ? "0 10px 25px -5px rgba(0, 0, 0, 0.3)"
                  : "0 10px 25px -5px rgba(0, 0, 0, 0.15)";
                e.currentTarget.style.backgroundColor = isDarkMode
                  ? "rgba(0, 0, 0, 0.6)"
                  : "rgba(255, 255, 255, 1)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow = isDarkMode
                  ? "0 4px 6px rgba(0, 0, 0, 0.2)"
                  : "0 4px 6px rgba(0, 0, 0, 0.1)";
                e.currentTarget.style.backgroundColor = isDarkMode
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.9)";
              }}
            >
              <h3
                className="text-xl font-semibold mb-2"
                style={{ color: colors.software }}
              >
                {t("solutions.cards.software.title")}
              </h3>
              <p
                className="text-base font-medium"
                style={{
                  color: isDarkMode ? "white" : "black",
                  textShadow: isDarkMode ? "0 1px 2px rgba(0,0,0,0.8)" : "none",
                }}
              >
                {t("solutions.cards.software.description")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
